import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import HeaderExpanded from "../components/HeaderExpanded/HeaderExpanded";
import "../styles/support.scss";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

function Support() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const image = process.env.GATSBY_S3_BUCKET_ASSETS + "images/support.jpg";

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Support</title>
        <meta name="description" content="HortPlus Support" />
      </Helmet>
      <HeaderExpanded title={"Support"} image={image} />
      <Container className="hp-page text-center">
        <p>
        <h3><a
          className="text-dark"
          href="mailto:support@hortplus.com?subject=Support"
        >
          support@hortplus.com
        </a></h3></p>
        or use the onscreen widget at the bottom of the screen!
      </Container>
    </>
  );
}

export default Support;
